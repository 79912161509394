import React from 'react';
import PropTypes from 'prop-types';

import Theme from 'components/atoms/objects/Theme/Theme';
import Layer from 'components/atoms/objects/Layer/Layer';
import Retain from 'components/atoms/objects/Retain/Retain';

const FormBlock = (props) => {
  const {
    children,
  } = props;

  return (
    <Theme color="grey-b">
      <Layer size="large">
        <Retain size="small">
          { children }
        </Retain>
      </Layer>
    </Theme>
  );
};

FormBlock.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FormBlock;
