import PropTypes from 'prop-types';
import { Formik } from 'formik';
import React, { useRef } from 'react';
import useFormReturnValidationHook from 'utilities/Hooks/FormReturnValidationHook';

import './Form.scss';

const Form = ({
  initialValues,
  validation,
  validate,
  form,
  onSubmit,
}) => {
  const ref = useRef(null);
  useFormReturnValidationHook(ref, initialValues);

  return (
    <Formik
      forwardRef={ref}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validation}
      validate={validate}
      className="c-form"
      component={(renderParams) => form(renderParams)}
    />
  );
};

Form.propTypes = {
  initialValues: PropTypes.objectOf(PropTypes.any).isRequired,
  validation: PropTypes.objectOf(PropTypes.any),
  validate: PropTypes.func,
  form: PropTypes.func.isRequired,
  /*
    parameters: (values, actions)
    make sure to call actions.setSubmitting(false) to flag the end of the callback.
   */
  onSubmit: PropTypes.func.isRequired,
};

Form.defaultProps = {
  validation: undefined,
  validate: undefined,
};

export default Form;
