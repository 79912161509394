import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/atoms/Icons/Icon';

import './ErrorLabel.scss';

const ErrorLabel = (props) => {
  const {
    label,
  } = props;

  return (
    <div className="c-error-label  o-layout  o-layout--gutter-tiny">
      <div className="o-layout__cell  o-layout__cell--fit">
        <Icon icon="icon-alert" className="c-error-label__icon" size="delta" />
      </div>
      <div className="o-layout__cell  o-layout__cell--fill">
        {label}
      </div>
    </div>
  );
};

ErrorLabel.propTypes = {
  label: PropTypes.string,
};

ErrorLabel.defaultProps = {
  label: 'Label for error message',
};

export default ErrorLabel;
