import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormField from '../FormField/FormField';

import './Inputs.scss';

const InputText = (props) => {
  const {
    name,
    type,
    placeholder,
    render,
    isRequired,
    onChange,
  } = props;

  const [hasFocus, setHasFocus] = useState(false);

  return (
    <FormField {...props} focus={hasFocus}>
      <input
        id={name}
        type={type}
        name={name}
        onChange={
          (e) => {
            render.handleChange(e);
            if (onChange) {
              onChange(e.target.value);
            }
          }
        }
        onBlur={(e) => {
          // call the built-in handleBur
          render.handleBlur(e);
          // and do something about e
          setHasFocus(false);
        }}
        onFocus={() => { setHasFocus(true); }}
        placeholder={placeholder}
        value={render.values[name]}
        className="c-input"
        required={isRequired}
      />
    </FormField>
  );
};

InputText.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'email', 'number', 'hidden', 'password', 'tel']).isRequired,
  /* Render actually is required when rendering this component,
  however it is provided by the Form component. */
  render: PropTypes.objectOf(PropTypes.any).isRequired,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  onChange: PropTypes.func,
};

InputText.defaultProps = {
  placeholder: null,
  isRequired: false,
  onChange: null,
};

export default InputText;
