import React from 'react';
import PropTypes from 'prop-types';

import Theme from 'components/atoms/objects/Theme/Theme';
import Layer from 'components/atoms/objects/Layer/Layer';
import Retain from 'components/atoms/objects/Retain/Retain';
import Text from 'components/molecules/Text/Text';
import ListButtons from 'components/molecules/ListButtons/ListButtons';
import ListContact from 'components/molecules/ListContact/ListContact';

const ContactOptionsBlock = (props) => {
  const {
    data,
    listButtons,
    themeColor,
  } = props;

  return (
    <Theme color={themeColor}>
      <Layer size="large" id={data.id}>
        <Retain size="desk">
          <Text {...data} />

          <div className="o-layout  o-layout--align-center">
            <ListContact />
          </div>

          <div className="o-layout  o-layout--align-center">
            <ListButtons {...listButtons} />
          </div>

        </Retain>
      </Layer>
    </Theme>
  );
};

ContactOptionsBlock.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  listButtons: PropTypes.objectOf(PropTypes.any),
  themeColor: PropTypes.string,
};

ContactOptionsBlock.defaultProps = {
  data: null,
  listButtons: null,
  themeColor: '',
};

export default ContactOptionsBlock;
