import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Label from 'components/atoms/FormFields/Label/Label';
import ErrorLabel from 'components/atoms/FormFields/ErrorLabel/ErrorLabel';

import './FormField.scss';

const FormField = (props) => {
  const {
    children,
    name,
    render,
    hasFocus,
    className,
    hideLabel,
    hideValidationLabel,
    isHidden,
  } = props;

  return (
    <div className={classNames('c-form-field', {
      'c-form-field--valid  is-valid': (!render.errors[name] && render.touched[name]),
      'c-form-field--invalid  is-invalid': (render.errors[name] && render.touched[name] && !hasFocus),
      'u-hidden': (isHidden),
    }, className)}
    >
      <div className="c-form-field__label">
        <Label {...props} forId={name} isHidden={hideLabel} />
      </div>
      <div className="c-form-field__element">
        {children}
      </div>
      {render.errors[name]
      && render.touched[name]
      && !hasFocus
      && !hideValidationLabel
      && (
        <ErrorLabel label={render.errors[name]} />
      )}
    </div>
  );
};

FormField.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string.isRequired,
  /* Render actually is required when rendering this component,
  however it is provided by the Form component. */
  render: PropTypes.objectOf(PropTypes.any).isRequired,
  hasFocus: PropTypes.bool,
  className: PropTypes.string,
  hideLabel: PropTypes.bool,
  hideValidationLabel: PropTypes.bool,
  isHidden: PropTypes.bool,
};

FormField.defaultProps = {
  children: [],
  hasFocus: false,
  className: '',
  hideLabel: false,
  hideValidationLabel: false,
  isHidden: false,
};

export default FormField;
