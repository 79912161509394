import React from 'react';
// import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { object, string } from 'yup';

import Form from 'components/molecules/Forms/Form';
import InputText from 'components/atoms/FormFields/Inputs/InputText';
import TextArea from 'components/atoms/FormFields/Inputs/InputTextArea';
import Button from 'components/atoms/Button/Button';

const ContactForm = () => {
  const encode = (data) => Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');

  return (
    <Form
      initialValues={{
        name: '',
        email: '',
        phone: '',
        question: '',
      }}
      validation={object().shape({
        name: string()
          .required('Vul een naam in'),
        phone: string()
          .required('Vul een Telefoonnummer in'),
        email: string()
          .email('Vul een geldig emailadres in')
          .required('Vul een emailadres in'),
        question: string()
          .required('Vul een vraag in'),
      })}
      onSubmit={(values, actions) => {
        const {
          name,
          phone,
          email,
          question,
        } = values;

        fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({
            'form-name': 'ContactForm',
            name,
            phone,
            email,
            question,
          }),
        }).then(() => navigate('/contact-bedankt'));

        actions.setSubmitting(false);
      }}
      form={(render) => (
        <form
          name="ContactForm"
          method="post"
          className="c-form"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          action="/contact-bedankt"
          onSubmit={render.handleSubmit}
          noValidate
        >
          <InputText
            placeholder="Naam"
            label="Naam"
            name="name"
            type="text"
            render={render}
            isRequired
          />

          <InputText
            placeholder="Telefoonnummer"
            label="Telefoonnummer"
            name="phone"
            type="text"
            render={render}
            isRequired
          />

          <InputText
            placeholder="E-mailadres"
            label="E-mailadres"
            name="email"
            type="text"
            render={render}
            isRequired
          />

          <TextArea
            placeholder="Typ hier uw vraag"
            label="Vraag"
            name="question"
            type="text"
            render={render}
          />

          <div className="o-layout  o-layout--align-middle  o-layout--direction-column">
            <div className="o-layout__cell">
              <Button
                tag="button"
                disabled={render.isSubmitting}
                type="submit"
                text="Verstuur"
                icon="icon-arrow-right"
                reversed
              />
            </div>
          </div>
        </form>
      )}
    />
  );
};

// ContactForm.propTypes = {
//   onSubmit: PropTypes.func,
// };

// ContactForm.defaultProps = {
//   onSubmit: null,
// };

export default ContactForm;
