import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormField from '../FormField/FormField';

import './Inputs.scss';

const InputTextArea = (props) => {
  const {
    name,
    placeholder,
    render,
    isRequired,
    isHidden,
  } = props;

  const [hasFocus, setHasFocus] = useState(false);

  return (
    <FormField
      {...props}
      focus={hasFocus}
      isHidden={isHidden}
    >
      <textarea
        id={name}
        name={name}
        rows="4"
        onChange={
          (e) => {
            render.handleChange(e);
          }
        }
        onBlur={(e) => {
          // call the built-in handleBur
          render.handleBlur(e);
          // and do something about e
          setHasFocus(false);
        }}
        onFocus={() => { setHasFocus(true); }}
        placeholder={placeholder}
        value={render.values[name]}
        className="c-input"
        required={isRequired}
      />
    </FormField>
  );
};

InputTextArea.propTypes = {
  name: PropTypes.string.isRequired,
  /* Render actually is required when rendering this component,
  however it is provided by the Form component. */
  render: PropTypes.objectOf(PropTypes.any).isRequired,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  isHidden: PropTypes.bool,
};

InputTextArea.defaultProps = {
  placeholder: null,
  isRequired: false,
  isHidden: false,
};

export default InputTextArea;
