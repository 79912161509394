import React from 'react';

import './ListContact.scss';

const ListContact = () => (
  <div className="c-list-contact">
    <div className="o-layout  o-layout--gutter">
      <div className="o-layout__cell  u-fraction--6/12@from-lap">
        <div className="c-list-contact__column">
          <ul className="o-list-clean">
            <li>Spagetstraat 8</li>
            <li>5708 AL Helmond</li>
            <li>KVK 62908758</li>
          </ul>
        </div>
      </div>
      <div className="o-layout__cell  u-fraction--6/12@from-lap">
        <div className="c-list-contact__column">
          <ul className="o-list-clean">
            <li><a href="mailto:info@aertsbeton.nl">info@aertsbeton.nl</a></li>
            <li><a href="tel:06 20 94 40 84">06 20 94 40 84</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
);

export default ListContact;
