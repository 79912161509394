import React from 'react';
import PropTypes from 'prop-types';

import Heading from 'components/atoms/text/Heading/Heading';
import Rte from 'components/atoms/text/Rte/Rte';
import Button from 'components/atoms/Button/Button';
import InviewAnimation from 'components/molecules/InviewAnimation/InviewAnimation';

import './Text.scss';

const TextImage = (props) => {
  const {
    heading,
    richText,
    button,
  } = props;

  return (
    <InviewAnimation>
      <div
        className="o-layout  o-layout--gutter-huge  o-layout--align-middle"
      >
        <div className="o-layout__cell  u-mb">
          <Heading {...heading} />

          <Rte {...richText} />

          { button ? (
            <Button
              {...button}
            />
          ) : null }
        </div>
      </div>
    </InviewAnimation>
  );
};

TextImage.propTypes = {
  heading: PropTypes.objectOf(PropTypes.any),
  richText: PropTypes.objectOf(PropTypes.any),
  button: PropTypes.objectOf(PropTypes.any),
  image: PropTypes.objectOf(PropTypes.any),
  reverseLayout: PropTypes.bool,
};

TextImage.defaultProps = {
  heading: {},
  richText: {},
  button: null,
  image: null,
  reverseLayout: false,
};

export default TextImage;
