import React from 'react';
import SEO from 'components/seo';

// Component
import ContactOptionsBlock from 'components/organisms/Blocks/ContactOptionsBlock/ContactOptionsBlock';
import FormBlock from 'components/organisms/Blocks/FormBlock/FormBlock';
import ContactForm from 'components/molecules/Forms/ContactForm/ContactForm';

// Data
import ContactData from 'data/ContactData/ContactData.json';

const ContactPage = () => (
  <>
    <SEO title="Contact" />
    <ContactOptionsBlock
      data={ContactData.contactOptions}
      listButtons={ContactData.contactOptions.listButtons}
    />
    <FormBlock>
      <ContactForm />
    </FormBlock>
  </>
);

export default ContactPage;
