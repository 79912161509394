import { useEffect } from 'react';

/**
 * setTouched on the provided actions.
 * Set's all fields in initialValues to true.
 * @param {FormikActions<Values>} actions provided by the getFormikActions method on an Formik form.
 * @param {Object} initialValues.
 */
const setTouched = (actions, initialValues) => {
  const all = { ...initialValues };

  Object.keys(all).forEach((key) => {
    if (all[key] === null || all[key] === '') {
      delete all[key];
      return;
    }
    all[key] = true;
  });

  actions.setTouched(all);
};

const useFormReturnValidationHook = (formikRef, initialValues) => {
  useEffect(() => {
    if (formikRef.current) {
      const actions = formikRef.current.getFormikActions();
      setTouched(actions, initialValues);
    }
  }, [formikRef]);
};

export default useFormReturnValidationHook;
