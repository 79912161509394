import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Label.scss';

const Label = (props) => {
  const {
    label,
    forId,
    isRequired,
    isHidden,
  } = props;

  return (
    <label
      className={classNames('c-label', {
        'is-required': isRequired,
        'u-visually-hidden': isHidden,
      })}
      htmlFor={forId}
    >
      {label}
    </label>
  );
};

Label.propTypes = {
  label: PropTypes.string,
  forId: PropTypes.string,
  isRequired: PropTypes.bool,
  isHidden: PropTypes.bool,
};

Label.defaultProps = {
  label: 'Label for input',
  forId: 'input-text',
  isRequired: false,
  isHidden: false,
};

export default Label;
